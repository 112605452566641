<template>
  <div id="MyPageDetail">
    <div class="mypage_header">
      <div class="banner_img_box" :style="[memberInfo.bannerImageUrl ? {backgroundImage:`url(${memberInfo.bannerImageUrl})`} : {}]">
        <div class="mypage-info pc">
          <MyPageDetailInfo
            :isArtist="isArtist"
            :artistInfo="artistInfo"
          />
        </div>
      </div>
      <div class="mypage-info mobile">
        <MyPageDetailInfo
          :isArtist="isArtist"
          :artistInfo="artistInfo"
        />
      </div>
    </div>
    <div class="mypage_content">

      <div class="mypage_tabs" :class="{is_mobile: isMobile, is_artist: isArtist}">
        <b-tabs class="custom_tabs" v-model="selectedTab">
          <!-- (Tab 0) Created -->
          <b-tab v-if="isArtist" lazy>
            <template #title>
              Created
            </template>
            <NFTs ref="nfts" :idxArtist="memberInfo.idxArtist" />
          </b-tab>
          <!-- (Tab 1) Collected -->
          <b-tab v-if="!isCorporation" lazy>
            <template #title>
              {{ $t("mypage.collected") }}
            </template>
            <Collection ref="collection" @successProfileSetting="requestMyInfo" />
          </b-tab>
          <!-- (Tab 2) Favorites -->
          <b-tab lazy>
            <template #title>
              {{ $t("mypage.favorited") }}
            </template>
            <FavoritedCollections :idxMember="me.idx" />
          </b-tab>
          <!-- (Tab 3) 결제내역 -->
          <b-tab v-if="!isCorporation">
            <template #title>
              {{ $t("mypage.payment") }}
            </template>
            <!-- 결제내역 검색 -->
            <div class="search-section"
                 v-if="!isCorporation"
            >
              <div class="keyword_search_box float-left">
                <b-form-input
                  type="text"
                  placeholder="Search..."
                  autocomplete="off"
                  v-model="externalParams.keywordProductName"
                  @keypress.enter.prevent="onSearchPaymentList"
                />
                <b-button @click="onSearchPaymentList" class="search_btn px-0">
                  <img src="/img/threespace/icon/search_icon.png" />
                </b-button>
                <b-button
                  @click="onClickResetFilterKeyword"
                  v-if="externalParams.keywordProductName !== ''"
                  class="search_reset_btn px-0"
                >
                  <img src="/img/threespace/icon/search_reset_icon.png" />
                </b-button>
              </div>
              <div class="sort-section">
                <b-button
                  @click.stop="showSortByFilter"
                  class="sort_btn float-right"
                  :class="{ active: visibleSortBy }"
                >
                  {{ Object.keys(sortSelected).length > 0 ? sortSelected.text : $t("product.sortBy") }}
                  <img
                    class="float-right"
                    src="/img/threespace/icon/sortby_arrow.png"
                  />
                </b-button>
                <div
                  class="sort_select_list"
                  :class="{ open: visibleSortBy }"
                  v-click-outside:showSortByFilter="false"
                >
                  <template v-for="(item, i) in sortOptions">
                    <p
                      class="sort-item"
                      :key="i"
                      @click="onClickSortByItem(item)"
                      :class="{ active: sortSelected.text === item.text }"
                    >
                      {{ item.text }}
                    </p>
                  </template>
                </div>
              </div>
            </div>
            <PaymentList ref="paymentList" />
          </b-tab>
        </b-tabs>
      </div>
    </div>

    <MyPageContractSelectModal ref="myPageContractSelectModal" />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Collection from "./mypage/MyPageCollection";
import PaymentList from "./mypage/PaymentList";
import MyPageDetailInfo from './mypage/MyPageDetailInfo.vue';
import NFTs from './mypage/NFTs.vue';
import MyPageDetailInfoMobile from './mypage/MyPageDetailInfoMobile.vue';
import MyPageContractSelectModal from './mypage/MyPageContractSelectModal.vue';
import FavoritedCollections from './components/common/FavoritedCollections.vue';

export default {
  name: "MyPageDetail",
  components: {
    Collection,
    PaymentList,
    MyPageDetailInfo,
    NFTs,
    MyPageDetailInfoMobile,
    MyPageContractSelectModal,
    FavoritedCollections
  },
  props: {
    updateComplete: {
      type: Boolean,
      default: false      
    }
  },
  data() {
    return {
      windowWidth: 1920,
      selectedTab: 0,
      copiedEthAddr: false,
      copiedKlayAddr: false,
      externalParams: {
        keywordProductName: "",
      },
      sortSelected: {},
      sortOptions: [
        {
          value: { id: "orderDate", desc: "desc" },
          text: this.$t("product.sortByRecentlySold"),
        },
        {
          value: { id: "amt", desc: "desc" },
          text: this.$t("product.sortByHighestPrice"),
        },
        {
          value: { id: "amt", desc: "asc" },
          text: this.$t("product.sortByLowestPrice"),
        },
      ],
      visibleSortBy: false,
      artistInfo: {}
    };
  },
  mounted() {
    this.windowWidth = window.innerWidth;
    window.addEventListener('resize', this.handleResize);
    this.$nextTick(() => {
      this.requestMyInfo();
      if (this.$route.params.updateComplete) {
        this.$store.commit("setCommonAlertModalInfo", {
          msgHtml: this.$t("mypage.updatedProfile"),
          show: true,
          fontSize: "sm"
        })
      }
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  directives: {
    "click-outside": {
      bind: function(el, binding, vnode) {
        // create event
        el.eventClickOutside = function(event) {
          if (!(el == event.target || el.contains(event.target))) {
            vnode.context[binding.arg](binding.value);
          }
        };
        // event binding
        document.body.addEventListener("click", el.eventClickOutside);
      },
      unbind: function(el) {
        document.body.removeEventListener("click", el.eventClickOutside);
      }
    }
  },
  computed: {
    ...mapState("profile", ["memberInfo"]),
    ...mapState("auth", ["me", "isCorporation"]),
    ...mapState(["wallet"]),
    isMobile() {
      return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    },
    isArtist() {
      if (this.memberInfo.roles != undefined) {
        let artistIndex = this.memberInfo.roles.findIndex((el) => {
          return el.code === "ROLE_ARTIST"
        })
        if (artistIndex === -1) {
          return false
        } else {
          return true
        }
      } else {
        return false
      }
    },
  },
  methods: {
    ...mapActions("profile", ["getProfile"]),
    ...mapActions("gallery", ["getGalleryArtist"]),
    handleResize(event) {
      this.windowWidth = window.innerWidth;
    },
    requestMyInfo() {
      if (this.me.idx !== undefined) {
        this.getProfile(this.me.idx)
          .then(() => {
            if (this.isArtist) {
              this.requestArtistInfo();
            }
          })
          .catch(response => {
            this.$log.error(response);
            this.$router.push("/error/500");
          });
      }
    },
    requestArtistInfo() {
      if (this.isArtist) {
        this.getGalleryArtist(this.memberInfo.idxArtist).then(data => {
          if (
            Object.keys(data).length !== 0 &&
            JSON.stringify(data) !== JSON.stringify({})
          ) {
            this.artistInfo = data;
            this.selectedTab = 0;
          }
        });
      }
    },
    showSelectContractModal() {
      this.$refs["myPageContractSelectModal"].show();
    },
    onSearchPaymentList() {
      this.$refs["paymentList"].onClickSearch(this.externalParams.keywordProductName);
    },
    onClickResetFilterKeyword() {
      this.externalParams.keywordProductName = "";
      this.$refs["paymentList"].onClickSearch(this.externalParams.keywordProductName);
    },
    showSortByFilter(value) {
      if (typeof value === "boolean") this.visibleSortBy = value;
      else {
        this.visibleSortBy = !this.visibleSortBy;
      }
    },
    onClickSortByItem(item) {
      this.visibleSortBy = false;
      // this.currentPage = 1;
      this.sortSelected = item;
      this.$refs["paymentList"].onClickSortByItem(item);
    },
    onCopyEthAddr() {
      this.copiedEthAddr = true;
      setTimeout(() => {
        this.copiedEthAddr = false;
      }, 3000);
    },
    onCopyKlayAddr() {
      this.copiedKlayAddr = true;
      setTimeout(() => {
        this.copiedKlayAddr = false;
      }, 2000);
    },
    replaceNewLine(str) {
      if (str !== undefined && str !== null && str !== "") {
        return str.replace(/(?:\r\n|\r|\n)/g, "<br />");
      } else {
        return "";
      }
    },
  }
};
</script>
