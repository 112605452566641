<template>
  <div class="payment_list">
    <template v-for="(item, index) in paymentList.content">
      <div
        :key="'item' + index"
        class="payment_item"
        :class="index === 0 ? '' : 'mt-3'"
      >
        <div class="item_header">
          <span>{{ paymentList.content.length - index }}.</span>
          <span>{{ $t("mypage.orderDate") }}</span>
          <span>{{ item.orderDate | moment("YYYY-MM-DD HH:mm:ss") }}</span>
          <b-button
            v-b-toggle
            variant="link"
            :href="'#item-' + index"
            @click.prevent
          >
            <span class="m-0">{{ $t("mypage.details") }}</span>
            <v-icon class="when-closed">mdi-chevron-down</v-icon>
            <v-icon class="when-opened">mdi-chevron-up</v-icon>
          </b-button>
        </div>
        <div class="item_content_wrap">
          <div class="item_content item_content01">
            <div class="title_box">
              <template v-if="isProductFileTypeImage(item.product.displayCardFileType)">
                <b-img
                  :src="replaceDisplayCardUrl(item.product)"
                  class="cursor-pointer"
                  @click="showProductDetail(item.idxProduct)"
                ></b-img>
              </template>
              <template v-else>
                <video
                  :poster="replaceDisplayPosterUrl(item.product)"
                  :src="replaceDisplayCardUrl(item.product)"
                  type="video/webm"
                  height="150px"
                  muted
                  loop
                  autoplay
                  playsinline
                  class="cursor-pointer"
                  @click="showProductDetail(item.idxProduct)"
                ></video>
              </template>
            </div>
            <div class="info_box">
              <!-- 작품명 -->
              <p>{{ $t("mypage.artwork") }}</p>
              <p>
                <template v-if="activeLanguage !== undefined && activeLanguage === 'ko'">
                  {{ item.product.productName || item.product.productNameEn }}
                </template>
                <template v-else>
                  {{ item.product.productNameEn || item.product.productName }}
                </template>
              </p>
              <!-- 결제금액 -->
              <p>{{ $t("mypage.paymentAmount") }}</p>
              <p>
                <template v-if="item.payType === 'VBANK' || item.payType === 'CARD' || item.payType === 'TRANSFER'">
                  {{ item.amt | currency }} {{ item.currency }}
                </template>
                <template v-else>
                  {{ item.amountCoin | currencyCoin(item.currency) }} {{ item.currency }} ({{ item.amt | currency }} KRW)
                </template>
              </p>
              <!-- 결제상태 -->
              <p>{{ $t("mypage.status") }}</p>
              <p>
                <template v-if="item.payType === 'VBANK'">
                  {{ $t("mypage.payTypeVbank") }}
                </template>
                <template v-else-if="item.payType === 'CARD'">
                  {{ $t("mypage.payTypeCard") }}
                </template>
                <template v-else-if="item.payType === 'TRANSFER'">
                  {{ $t("mypage.payTypeTransfer") }}
                </template>
                <template v-else-if="item.payType === 'KLAY'">
                  {{ $t("mypage.payTypeKlay") }}
                </template>
                <template v-else-if="item.payType === 'MATIC'">
                  {{ $t("mypage.payTypeMATIC") }}
                </template>
                <template v-else-if="item.payType === 'SSX'">
                  {{ $t("mypage.payTypeSSX") }}
                </template>
                <template v-else-if="item.payType === 'ETH'">
                  {{ $t("mypage.payTypeEth") }}
                </template>
                ({{ replacePaymentStateName(item.stateCd) }})
              </p>
            </div>
          </div>
        </div>
        <b-collapse :id="'item-' + index">
          <div class="item_content_wrap">
            <!-- 작품정보 ========================================= -->
            <div class="item_content">
              <div class="title_box">{{ $t("mypage.nftDetails") }}</div>
              <div class="info_box">
                <!-- 작품명 -->
                <p>{{ $t("mypage.artwork") }}</p>
                <p>
                  <template v-if="activeLanguage !== undefined && activeLanguage === 'ko'">
                    {{ item.product.productName || item.product.productNameEn }}
                  </template>
                  <template v-else>
                    {{ item.product.productNameEn || item.product.productName }}
                  </template>
                </p>
                <!-- 작가명 -->
                <p>{{ $t("mypage.artist") }}</p>
                <p>
                  <template v-if="activeLanguage !== undefined && activeLanguage === 'ko'">
                    {{ item.product.artistName || item.product.artistNameEn }}
                  </template>
                  <template v-else>
                    {{ item.product.artistNameEn || item.product.artistName }}
                  </template>
                  (@{{ item.product.artistNickName }})
                <!-- 작품 NFT 고유번호 -->
                <p>{{ $t("mypage.nftTokenId") }}</p>
                <p>
                  <template v-if="item.product.nftType !== undefined && item.product.nftType !== null">
                    <template v-if="item.product.nftType === 'ETHEREUM'">
                      <CImg height="18" src="/img/threespace/company/ethereum-eth-logo.svg" />
                    </template>
                    <template v-else-if="item.product.nftType === 'POLYGON'">
                      <CImg height="16" src="/img/threespace/company/polygon-matic-logo.png" />
                    </template>
                    <template v-else-if="item.product.nftType === 'KLAYTN' || item.product.nftType === 'KLIP'">
                      <CImg height="16" src="/img/threespace/company/kaia-black.png" />
                    </template>
                  </template>
                  #{{ item.product.tokenId || '(정보없음)' }}
                </p>
              </div>
            </div>
            <!-- 구매자 정보 ========================================= -->
            <div class="item_content">
              <div class="title_box">{{ $t("mypage.accountInfo") }}</div>
              <div class="info_box">
                <!-- 사용자 이름 -->
                <p>{{ $t("mypage.username") }}</p>
                <p>{{ item.buyerName || "-" }}</p>
                <!-- 연락처 -->
                <p>{{ $t("mypage.mobile") }}</p>
                <p>{{ item.buyerTel || "-" }}</p>
                <!-- 계정 이메일 -->
                <p>{{ $t("mypage.email") }}</p>
                <p>{{ item.buyerEmail || "-" }}</p>
                <!-- nft 수신 주소 -->
                <p>{{ $t("mypage.address") }}</p>
                <p>
                  <template v-if="item.product.nftType !== undefined && item.product.nftType !== null">
                    <template v-if="item.product.nftType === 'ETHEREUM'">
                      {{ memberInfo.ethereumAddr || '(정보없음)' }}
                    </template>
                    <template v-else-if="item.product.nftType === 'KLAYTN' || item.product.nftType === 'KLIP'">
                      {{ memberInfo.klaytnAddr || '(정보없음)' }}
                    </template>
                  </template>
                  <template v-if="item.nftReceipt.klaytnScopeUrl !== undefined && item.nftReceipt.klaytnScopeUrl !== ''">
                    <a :href="item.nftReceipt.klaytnScopeUrl" target="_blank">
                      <CIcon name="cil-external-link" />
                    </a>
                  </template>
                  <span class="d-block pt-3">
                    {{ $t("mypage.transactionHistory") }}
                    <font-awesome-icon icon="exclamation-circle" :id="'nft-info-' + index" />
                    <b-tooltip :target="'nft-info-' + index" triggers="hover">
                      {{ $t("mypage.txHistory") }}
                    </b-tooltip>
                  </span>
                </p>
              </div>
            </div>
            <!-- 결제 정보 ========================================= -->
            <div class="item_content">
              <div class="title_box">{{ $t("mypage.nftTransaction") }}</div>
              <div class="info_box">
                <!-- 최종 판매가 -->
                <p>{{ $t("mypage.amount") }}</p>
                <p>
                  <template v-if="item.payType === 'VBANK' || item.payType === 'CARD' || item.payType === 'TRANSFER'">
                    {{ item.amt | currency }} {{ item.currency }}
                  </template>
                  <template v-else>
                    {{ item.amountCoin | currency }} {{ item.currency }} ({{ item.amt | currency }} KRW)
                  </template>
                </p>
                <!-- 결제 수단 -->
                <p>{{ $t("mypage.method") }}</p>
                <p>
                  <template v-if="item.payType === 'VBANK'">
                    {{ $t("mypage.payTypeVbank") }}
                  </template>
                  <template v-else-if="item.payType === 'CARD'">
                    {{ $t("mypage.payTypeCard") }}
                  </template>
                  <template v-else-if="item.payType === 'TRANSFER'">
                    {{ $t("mypage.payTypeTransfer") }}
                  </template>
                  <template v-else-if="item.payType === 'KLAY'">
                    {{ $t("mypage.payTypeKlay") }}
                  </template>
                  <template v-else-if="item.payType === 'MATIC'">
                    {{ $t("mypage.payTypeMATIC") }}
                  </template>
                  <template v-else-if="item.payType === 'SSX'">
                    {{ $t("mypage.payTypeSSX") }}
                  </template>
                  <template v-else-if="item.payType === 'ETH'">
                    {{ $t("mypage.payTypeEth") }}
                  </template>
                  ({{ replacePaymentStateName(item.stateCd) }})
                </p>
                <template v-if="item.payType === 'VBANK'">
                  <!-- 계정 번호 -->
                  <p>{{ $t("mypage.accountNumber") }}</p>
                  <p>
                    <span class="mr-2">{{ item.fnName }} {{ item.vbankNum }}</span>
                    <template v-if="item.stateCd === '9'">
                      <v-icon small>mdi-alert</v-icon>
                      <span class="font-sm">
                        {{ (item.vbankExpDate + "T" + item.vbankExpTime) | moment("YYYY년 MM월 DD일 HH시 mm분") }}까지 미입금시 주문이 자동 취소됩니다.
                      </span>
                    </template>
                  </p>
                  <!-- 현금영수증 -->
                  <p>{{ $t("mypage.cashReceipt") }}</p>
                  <p>
                    <span v-html="getReceiptInfo(item)"></span>
                  </p>
                </template>
              </div>
            </div>
          </div>
        </b-collapse>
      </div>
    </template>
    <template v-if="paymentList.content.length === 0">
      <div class="col-12 mb-5 py-5 text-center" key="0">
        <p class="font-lg">{{ $t("mypage.noHistoryPayment") }}</p>
      </div>
    </template>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const profileHelper = createNamespacedHelpers("profile");
const paymentHelper = createNamespacedHelpers("payment");

export default {
  name: "PaymentList",
  components: {},
  data() {
    return {
      externalParams: {
        keywordProductName: "",
        sort: ["orderDate,desc", "idx,desc"],
        size: 9999
      },
      paymentStateOptions: [
        { value: "0", text: this.$t("mypage.statusComplete") },
        { value: "1", text: this.$t("mypage.statusCancelledBefore") },
        { value: "2", text: this.$t("mypage.statusCancelledAfter") },
        { value: "3", text: this.$t("mypage.statusReturned") },
        { value: "4", text: this.$t("mypage.statusFail") },
        { value: "9", text: this.$t("mypage.statusPending") },
        { value: "10", text: this.$t("mypage.statusProcessing") },
      ],
    };
  },
  computed: {
    ...profileHelper.mapState(["memberInfo"]),
    ...paymentHelper.mapState(["paymentList"]),
    activeLanguage() {
      return this.getActiveLanguage();
    }
  },
  mounted() {
    this.requestOrderPaymentItems();
  },
  methods: {
    ...paymentHelper.mapActions(["getPaymentList"]),
    getActiveLanguage() {
      return localStorage.getItem("language") || "en";
    },
    onClickSearch(keyword) {
      this.externalParams.keywordProductName = keyword;
      this.requestOrderPaymentItems();
    },
    onClickSortByItem(item) {
      this.externalParams.sort[0] = `${item.value.id},${item.value.desc}`;
      this.requestOrderPaymentItems();
    },
    requestOrderPaymentItems() {
      // @TODO Paging 추가 되어야할것같음
      this.getPaymentList(this.externalParams).then(() => {});
    },
    isProductFileTypeImage(_displayCardFileType) {
      if (
        _displayCardFileType !== undefined &&
        _displayCardFileType !== null &&
        _displayCardFileType.startsWith("image")
      ) {
        return true;
      } else {
        return false;
      }
    },
    replaceDisplayPosterUrl(item) {
      if (
        item.displayPoster !== undefined &&
        item.displayPoster !== null &&
        item.displayPoster !== "" &&
        item.displayPoster === "THREESPACE"
      ) {
        return item.displayPosterUrlThreespace; // 3space S3 URL
      } else {
        if (item.displayPosterUrl !== undefined && item.displayPosterUrl.startsWith("https://ipfs.io/")) {
          return item.displayPosterUrl.replace("https://ipfs.io/", "https://ipfs.3space.art/");
        }
        return item.displayPosterUrl;
      }
    },
    replaceDisplayCardUrl(product) {
      if (
        product.displayCardFile !== undefined &&
        product.displayCardFile !== null &&
        product.displayCardFile !== "" &&
        product.displayCardFile === "THREESPACE"
      ) {
        return product.displayCardUrlThreespace; // 3space S3 URL
      } else {
        if (
          product.displayCardUrl !== undefined &&
          product.displayCardUrl.startsWith("https://ipfs.io/")
        ) {
          return product.displayCardUrl.replace(
            "https://ipfs.io/",
            "https://ipfs.3space.art/"
          );
        }
        return product.displayCardUrl;
      }
    },
    replacePaymentStateName(stateCd) {
      if (stateCd !== undefined && stateCd !== null) {
        let state = this.paymentStateOptions.find(o => o.value === stateCd);
        return state.text;
      } else {
        return "";
      }
    },
    getReceiptInfo(_item) {
      let receiptInfo = "(정보없음)";
      if (
        _item.receiptType !== undefined &&
        _item.receiptType !== null &&
        _item.receiptType !== ""
      ) {
        if (_item.receiptType === "0") {
          receiptInfo = this.$t("mypage.none");
        } else if (_item.receiptType === "1") {
          receiptInfo = this.$t("mypage.issued") + "(" + this.$t("mypage.personal") + ") " + _item.receiptTypeNo;
        } else if (_item.receiptType === "2") {
          receiptInfo = this.$t("mypage.issued") + "(" + this.$t("mypage.corporation") + ") " + _item.receiptTypeNo;
        }
      }
      return receiptInfo;
    },
    showProductDetail(idx) {
      this.$router.push("/art/" + idx);
    }
  },
  filters: {
    currency: value => {
      if (!value) return "";
      return parseInt(value)
        .toFixed(0)
        .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
    },
    currencyCoin: function(value,currency){
      if(currency === "ETH"){
        return parseFloat(Math.floor(value * 1000) / 1000)
          .toFixed(3)
          .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
      }

      return parseInt(value)
        .toFixed(0)
        .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
    }
  }
};
</script>

<style scoped>
.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}
.table-sm th,
.table-sm td {
  padding: 0.2rem;
}
</style>
