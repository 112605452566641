<template>
  <div>
    <b-modal
      id="contract-select-modal"
      ref="contract-select-modal"
      hide-header
      hide-footer
      centered
      scrollable
      no-close-on-esc
      no-close-on-backdrop
    >
      <CRow>
        <CCol class="col-12 py-2">
          <CLink @click="hide">
            <img class="float-right" src="/img/threespace/icon/x-icon.png" />
          </CLink>
        </CCol>
      </CRow>

      <!-- Chain (Ethereum, Klaytn) 선택 -->
      <div class="contract_select_wrap">
        <p class="title">{{ $t("mypage.nftImport") }}</p>
        <b-button
          block
          @click="onClickGetNftEthereumBtn"
          disabled
        >
          {{ $t('mypage.ethereumNftImport') }}
        </b-button>
        <b-button
          block
          @click="onClickGetNftPolygonBtn"
          disabled
        >
          {{ $t('mypage.polygonNftImport') }}
        </b-button>
        <b-button block @click="onClickGetNftKlaytnBtn">
          {{ $t("mypage.klaytnNftImport") }}
        </b-button>
        <div class="whitelist_info">
          <img src="/img/threespace/icon/layers_icon.png" />
          <p>{{ $t("mypage.whitelistTitle") }}</p>
          <p>{{ $t("mypage.ethereum") }}, {{$t("mypage.polygon")}} - Coming soon </p>
          <p>{{ $t("mypage.klaytn") }}  - Klip Drops </p>
        </div>
      </div>
    </b-modal>

    <!-- Klaytn External Collection Modal -->
    <KlaytnCollectionSelectModal
      ref="klaytnCollectionSelectModal"
      :isBusyKlaytn="isBusyKlaytn"
      :klaytnNftCollectionList="klaytnNftCollectionList"
      @clearNftCollectionList="clearNftCollectionList"
      @addExternalNftSuccess="addExternalNftSuccess"
    />

    <!-- Ethereum External Collection Modal -->
    <EthereumCollectionSelectModal
      ref="ethereumCollectionSelectModal"
      :isBusyEthereum="isBusyEthereum"
      :ethereumNftCollectionList="ethereumNftCollectionList"
      @clearNftCollectionList="clearNftCollectionList"
      @addExternalNftSuccess="addExternalNftSuccess"
    />

    <!-- Polygon External Collection Modal -->
    <PolygonCollectionSelectModal
      ref="polygonCollectionSelectModal"
      :isBusyPolygon="isBusyPolygon"
      :polygonNftCollectionList="polygonNftCollectionList"
      @clearNftCollectionList="clearNftCollectionList"
      @addExternalNftSuccess="addExternalNftSuccess"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import ExternalCollections from "@/mixins/ExternalCollections";
import KlaytnCollectionSelectModal from './KlaytnCollectionSelectModal.vue';
import EthereumCollectionSelectModal from './EthereumCollectionSelectModal.vue';
import PolygonCollectionSelectModal from './PolygonCollectionSelectModal.vue';

export default {
  data: function() {
    return {};
  },
  mounted() {},
  computed: {
    ...mapState("auth", ["me"])
  },
  components: {
    KlaytnCollectionSelectModal,
    EthereumCollectionSelectModal,
    PolygonCollectionSelectModal,
  },
  mixins: [
    ExternalCollections
  ],
  methods: {
    ...mapActions("collection", [
      "getCollectionsAll"
    ]),
    onClickGetNftEthereumBtn() {
      if (this.me.ethereumAddr !== undefined && this.me.ethereumAddr !== "") {
        this.getCollectionsAll({
          keywordIdxMember: this.me.idx,
          keywordStaked: false
        }).then(() => {
          // this.getEthereumNfts(this.me.ethereumAddr);
          this.getEthereumNfts2(this.me.ethereumAddr);

          this.hide();
          this.$refs["ethereumCollectionSelectModal"].show();
        })
      } else {
        this.$store.commit("setCommonAlertModalInfo", {
          msgHtml: this.$t("wallet.validation.requiredEthereum"),
          show: true,
          fontSize: "sm"
        })
      }
    },
    onClickGetNftPolygonBtn() {
      if (this.me.polygonAddr !== undefined && this.me.polygonAddr !== "") {
        this.getCollectionsAll({
          keywordIdxMember: this.me.idx,
          keywordStaked: false
        }).then(() => {
          this.getPolygonNfts(this.me.polygonAddr);
          this.hide();
          this.$refs["polygonCollectionSelectModal"].show();
        })
      } else {
        this.$store.commit("setCommonAlertModalInfo", {
          msgHtml: this.$t("wallet.validation.requiredPolygon"),
          show: true,
          fontSize: "sm"
        })
      }
    },
    onClickGetNftKlaytnBtn() {
      if (this.me.klaytnAddr !== undefined && this.me.klaytnAddr !== "") {
        this.getCollectionsAll({
          keywordIdxMember: this.me.idx,
          keywordStaked: false
        }).then(() => {
          this.getKlaytnNfts(this.me.klaytnAddr);
          this.hide();
          this.$refs["klaytnCollectionSelectModal"].show();
        })
      } else {
        this.$store.commit("setCommonAlertModalInfo", {
          msgHtml: this.$t("wallet.validation.requiredKlaytn"),
          show: true,
          fontSize: "sm"
        })
      }
    },
    clearNftCollectionList() {
      this.ethereumNftCollectionList = [];
      this.klaytnNftCollectionList = [];
    },
    addExternalNftSuccess() {
      this.$emit("addExternalNftSuccess")
    },
    show() {
      this.$refs["contract-select-modal"].show();
    },
    hide() {
      this.$refs["contract-select-modal"].hide();
    },
  },
};
</script>
