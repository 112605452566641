<template>
  <b-modal
    id="klaytn-collection-select-modal"
    ref="klaytn-collection-select-modal"
    hide-header
    hide-footer
    centered
    scrollable
    no-close-on-esc
    no-close-on-backdrop
    @hide="closeModal"
  >
    <CRow>
      <CCol class="col-12 py-2">
        <CLink @click="hide">
          <img class="float-right" src="/img/threespace/icon/x-icon.png" />
        </CLink>
      </CCol>
    </CRow>

    <!-- Contract 선택 =========================== -->
    <div class="contract_select_wrap klaytn_contract_select_wrap" v-if="!showCollection">
      <p class="title">
        {{ $t("mypage.klaytnNftImport") }}<br />
        <span class="small">
          {{ $t("mypage.selectContract") }}
        </span>
      </p>
      <template v-if="isBusyKlaytn">
        <div class="contract_select_list">
          <div class="sk-wave mx-auto mt-5">
            <div class="sk-wave-rect"></div>
            <div class="sk-wave-rect"></div>
            <div class="sk-wave-rect"></div>
            <div class="sk-wave-rect"></div>
            <div class="sk-wave-rect"></div>
          </div>
        </div>
      </template>
      <template v-else>
        <template v-if="klaytnNftCollectionList !== undefined && klaytnNftCollectionList.length > 0">
          <div class="contract_select_list">
            <b-button
              block
              @click="onClickContractItem(item)"
              v-for="(item, i) in klaytnNftCollectionList"
              :key="i"
            >
              {{ item.nft.name }} ({{ item.nft.address | simpleAddr }})
            </b-button>
          </div>
        </template>
        <!-- klaytnNftCollectionList Empty -->
        <template v-else>
          <div class="contract_select_list pt-5">
            {{ $t("mypage.noResultContract") }}
          </div>
        </template>
      </template>
    </div>

    <!-- Collection 선택 =========================== -->
    <div class="collection_select_wrap" v-else>
      <p class="title">
        {{ $t("mypage.klaytnNftImport") }}<br />
        <span class="contract_name">
          {{ selectedNftCollectionList[0].nft.name }} ({{ selectedNftCollectionList[0].nft.address | simpleAddr }})
        </span>
      </p>

      <div class="collection_select_list">
        <div class="total_cnt">
          <div class="float-left">
            {{ $t("mypage.selected") }} ({{ selectedCollections.length }}/{{ selectedNftCollectionList[0].collections.length }})
          </div>
          <div class="float-right">
            <img src="/img/threespace/icon/refresh_icon.png" />
          </div>
        </div>
        <b-table-simple
          sticky-header="330px"
          borderless
          no-border-collapse
          head-variant="light"
        >
          <b-thead>
            <tr>
              <th></th>
              <th class="text-left">
                {{ $t("mypage.tokenId") }}
              </th>
              <th></th>
              <th class="text-left">
                {{ $t("mypage.tokenId") }}
              </th>
            </tr>
          </b-thead>
          <b-tbody>
            <tr
              v-for="(chunkArry, i) in chunk(selectedNftCollectionList[0].collections, 2)"
              :key="i"
            >
              <template td v-for="(collection, j) in chunkArry">
                <td :key="'collection' + j" class="text-right pr-0">
                  <template v-if="isAlreadyRegister(collection)">
                    <v-icon>mdi-check-underline</v-icon>
                  </template>
                  <template v-else>
                    <b-form-checkbox
                      :id="'collection'+i+j"
                      v-model="selectedCollections"
                      :value="collection"
                      :disabled="selectedCollections.length > 4 && selectedCollections.indexOf(collection) === -1"
                    ></b-form-checkbox>
                  </template>
                </td>
                <td :key="'tokenId' + j" class="text-left">
                    <label class="m-0" :for="'collection'+i+j">#{{ collection.tokenId | hexToNumber }}</label>
                </td>
              </template>
            </tr>
          </b-tbody>
        </b-table-simple>
      </div>

      <div class="btn_box">
        <p class="text-grey">
          가져오기는 1회 당 최대 5개로 제한합니다.
        </p>
        <b-button class="prev_btn" @click="onClickPrev">
          {{ $t("button.prev") }}
        </b-button>
        <b-button class="next_btn" @click="onClickNext">
          {{ $t("button.next") }}
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  props: {
    isBusyKlaytn: {
      type: Boolean,
      default: false
    },
    klaytnNftCollectionList: {
      type: Array,
      default: () => []
    }
  },
  data: function() {
    return {
      selectedNftCollectionList: [],
      selectedCollections: [],
      showCollection: false,
    };
  },
  mounted() {},
  computed: {
    ...mapState("auth", ["me"]),
    ...mapState("collection", ["collectionAllList"]),
  },
  components: {},
  methods: {
    ...mapActions("nft", ["addExternalNftCollectionsKlaytn"]),
    onClickPrev() {
      this.showCollection = false;
      this.selectedCollections = [];
    },
    onClickNext() {
      // @TODO 승인 받을 수 있으면 승인 받기
      // get NFT
      if (this.selectedCollections.length > 0) {
        this.$store.commit("setShowLoading", { show: true, msg: "" });
        this.selectedNftCollectionList[0].collections = this.selectedCollections;
        this.addExternalNftCollectionsKlaytn(this.selectedNftCollectionList)
          .then(() => {
            this.$store.commit("setShowLoading", { show: false, msg: "" });
            this.$store.commit("setCommonAlertModalInfo", {
              msgHtml: this.$t("mypage.importModal.complete"),
              show: true,
              fontSize: "sm"
            })
            this.hide();
            this.$emit("addExternalNftSuccess")
          })
          .catch(() => {
            // @TODO Timeout 처리
            // msgHtml = "불러오기 처리가 지연되고 있습니다.<br />잠시 후 \"마이페이지\" > \"Collected\"에서 확인해주세요";
            this.$store.commit("setCommonAlertModalInfo", {
              msgHtml: this.$t("mypage.importModal.fail"),
              show: true,
              fontSize: "sm"
            })
            this.hide();
            this.$store.commit("setShowLoading", { show: false, msg: "" });

          });
      } else {
        alert("불러오기 할 tokenId를 선택해주세요.");
      }
    },
    onClickContractItem(nftObj) {
      // 하나만 선택 =======
      this.showCollection = true;
      this.selectedNftCollectionList = [];
      this.selectedNftCollectionList.push(nftObj);
    },
    isAlreadyRegister(collection) {
      let nft = this.selectedNftCollectionList[0].nft;
      
      let contractAddr = nft.address;
      let tokenId = this.$options.filters.hexToNumber(collection.tokenId);
      let rfc = nft.type;
      
      let index = this.collectionAllList.findIndex(
        el => el.tokenId === tokenId.toString() && 
              el.nft.rfc === rfc &&
              el.nft.contractAddr === contractAddr);
      if (index === -1) {
        return false
      } else {
        return true
      }
    },
    show() {
      this.$refs["klaytn-collection-select-modal"].show();
    },
    hide() {
      this.$refs["klaytn-collection-select-modal"].hide();
    },
    closeModal() {
      this.$log.info("close modal")
      this.$emit("clearNftCollectionList");
      this.showCollection = false;
      this.selectedNftCollectionList = [];
      this.selectedCollections = [];
    },
    chunk(array, size) {
      // 내부 배열을 생성
      const chunked = []
      // 주어진 배열을 탐색
      for (let element of array) {
        const last = chunked[chunked.length - 1]

        if (!last || last.length === size) {
          chunked.push([element])
        } else {
          last.push(element)
        }
      }
      return chunked
    }
  },
  filters: {
    simpleAddr: value => {
      if (value !== undefined) {
        return value.slice(0,5) + "..." + value.slice(-3)
      }
      return ""
    },
    hexToNumber: value => {
      if (value !== undefined) {
        if (value.indexOf("0x") !== -1) {
          let hex = value.replace("0x", "")
          var str = parseInt(hex, 16);
          return str;
        } else {
          return value;
        }
      } else {
        return "";
      }
    }
  }
};
</script>
