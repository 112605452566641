<template>
  <b-modal
    id="ethereum-collection-select-modal"
    ref="ethereum-collection-select-modal"
    hide-header
    hide-footer
    centered
    scrollable
    no-close-on-esc
    no-close-on-backdrop
    @hide="closeModal"
  >
    <CRow>
      <CCol class="col-12 py-2">
        <CLink @click="hide">
          <img class="float-right" src="/img/threespace/icon/x-icon.png" />
        </CLink>
      </CCol>
    </CRow>

    <!-- Collection 선택 =========================== -->
    <div class="collection_select_wrap">
      <p class="title">
        {{ $t("mypage.ethereumNftImport") }}
      </p>

      <template v-if="isBusyEthereum">
        <div class="contract_select_list">
          <div class="sk-wave mx-auto mt-5">
            <div class="sk-wave-rect"></div>
            <div class="sk-wave-rect"></div>
            <div class="sk-wave-rect"></div>
            <div class="sk-wave-rect"></div>
            <div class="sk-wave-rect"></div>
          </div>
        </div>
      </template>

      <template v-else>
        <template v-if="ethereumNftCollectionList !== undefined && ethereumNftCollectionList.length > 0">
          <div class="collection_select_list">
            <div class="total_cnt">
              <div class="float-left">
                {{ $t("mypage.selected") }} ({{ selectedNftCollectionList.length }}/{{ ethereumNftCollectionList.length }})
              </div>
              <div class="float-right">
                <img src="/img/threespace/icon/refresh_icon.png" />
              </div>
            </div>
            <b-table-simple
              sticky-header="330px"
              borderless
              no-border-collapse
              head-variant="light"
              fixed
            >
              <b-thead>
                <tr>
                  <th colspan="1"></th>
                  <th colspan="2">
                    {{ $t("mypage.productName") }}
                  </th>
                  <th colspan="2">
                    {{ $t("mypage.tokenId") }}
                  </th>
                  <th colspan="2">
                    {{ $t("mypage.contract") }}
                  </th>
                </tr>
              </b-thead>
              <b-tbody>
                <template
                  v-for="(item, i) in ethereumNftCollectionList"
                >
                  <tr
                    :key="i"
                    :class="{error_row: item.error !== undefined, already_row: isAlreadyRegister(item)}"
                  >
                    <td colspan="1">
                      <template v-if="isAlreadyRegister(item)">
                        <v-icon>mdi-check-underline</v-icon>
                      </template>
                      <template v-else>
                        <b-form-checkbox
                          :id="'collection'+i"
                          v-model="selectedNftCollectionList"
                          :value="item"
                          :disabled="
                            selectedNftCollectionList.length > 4 && 
                            selectedNftCollectionList.indexOf(item) === -1
                          "
                          v-if="item.error === undefined"
                        ></b-form-checkbox>
                      </template>
                    </td>
                    <td colspan="2">
                        <label class="m-0" :for="'collection'+i">
                          <template v-if="item.error !== undefined">
                            ERROR
                          </template>
                          <template v-else>
                            {{ item.title }}
                          </template>
                        </label>
                    </td>
                    <td colspan="2">
                        <label class="m-0" :for="'collection'+i">#{{ item.tokenId }}</label>
                    </td>
                    <td colspan="2">
                        <label class="m-0" :for="'collection'+i">{{ item.contract.address | simpleAddr }}</label>
                    </td>
                  </tr>
                </template>
              </b-tbody>
            </b-table-simple>
          </div>

          <div class="btn_box">
            <p class="text-grey">
              가져오기는 1회 당 최대 5개로 제한합니다.
            </p>
            <b-button class="next_btn" @click="onClickNext">
              {{ $t("button.next") }}
            </b-button>
          </div>
        </template>
        <template v-else>
          <div class="empty_box">
            {{ $t("stake.stakeModal.noData") }}
          </div>
        </template>
      </template>
    </div>
  </b-modal>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import BigNumber from "bignumber.js";

export default {
  props: {
    isBusyEthereum: {
      type: Boolean,
      default: false
    },
    ethereumNftCollectionList: {
      type: Array,
      default: () => []
    }
  },
  data: function() {
    return {
      selectedNftCollectionList: []
    };
  },
  mounted() {},
  computed: {
    ...mapState("auth", ["me"]),
    ...mapState("collection", ["collectionAllList"]),
  },
  components: {},
  methods: {
    ...mapActions("nft", ["addExternalNftCollectionsEthereum"]),
    onClickNext() {
      // @TODO 승인 받을 수 있으면 승인 받기
      // get NFT
      if (this.selectedNftCollectionList.length > 0) {
        this.$store.commit("setShowLoading", { show: true, msg: "" });

        this.addExternalNftCollectionsEthereum(this.selectedNftCollectionList)
          .then(() => {
            this.$store.commit("setShowLoading", { show: false, msg: "" });
            this.$store.commit("setCommonAlertModalInfo", {
              msgHtml: this.$t("mypage.importModal.complete"),
              show: true,
              fontSize: "sm"
            })
            this.hide();
            this.$emit("addExternalNftSuccess")
          })
          .catch(() => {
            this.$store.commit("setCommonAlertModalInfo", {
              msgHtml: this.$t("mypage.importModal.fail"),
              show: true,
              fontSize: "sm"
            })
            this.hide();
            this.$store.commit("setShowLoading", { show: false, msg: "" });
          });
      } else {
        alert("불러오기 할 tokenId를 선택해주세요.");
      }
    },
    show() {
      this.$refs["ethereum-collection-select-modal"].show();
    },
    hide() {
      this.$refs["ethereum-collection-select-modal"].hide();
    },
    closeModal() {
      this.$log.info("close modal")
      this.$emit("clearNftCollectionList");
      this.selectedNftCollectionList = [];
    },
    isAlreadyRegister(item) {
      if (item.tokenId !== undefined && item.tokenId !== "") {
        let contractAddr = item.contract.address;
        let rfc = item.tokenType;
        if (rfc.indexOf("-") === -1) {
          rfc = rfc.slice(0,3) + "-" + rfc.slice(3)
        }
        let index = this.collectionAllList.findIndex(
          el => el.tokenId === item.tokenId && 
                el.nft.rfc === rfc && 
                el.nft.contractAddr === contractAddr);
        if (index === -1) {
          return false
        } else {
          return true
        }
      } else {
        return true
      }
    }
  },
  filters: {
    simpleAddr: value => {
      if (value !== undefined) {
        return value.slice(0,5) + "..." + value.slice(-3)
      }
      return ""
    },
    hexToNumber: value => {
      if (value !== undefined) {
        if (value.indexOf("0x") !== -1) {
          // BigNumber
          var x = new BigNumber(value, 16);
          var result = x.toString(10);
          if (result.length > 10) {
            result = result.slice(0,10) + "..."
          }
          return result;
        } else {
          return value;
        }
      } else {
        return "";
      }
    }
  }
};
</script>
