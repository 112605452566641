<template>
  <div>
    <b-modal
      id="export-collection-modal"
      ref="export-collection-modal"
      hide-header
      hide-footer
      centered
      scrollable
      no-close-on-esc
      no-close-on-backdrop
      @hide="closeModal"
    >
      <CRow>
        <CCol class="col-12 py-2">
          <CLink @click="hide">
            <img class="float-right" src="/img/threespace/icon/x-icon.png" />
          </CLink>
        </CCol>
      </CRow>

      <!-- 내보내기 주소 선택 -->
      <div class="collection_select_wrap export_collection_wrap">
        <p class="title">
          {{ $t("mypage.nftExport") }}
        </p>
        <div class="input_box">
          <p>
            {{ $t("mypage.exportModal.destinationAddress") }}
          </p>
          <input
            type="text"
            :placeholder="$t('mypage.exportModal.placeholder')"
            v-model="toAddress"
            spellCheck="false"
          />
        </div>
        <div class="info_box">
          <p class="title">
            {{ $t("mypage.exportModal.reminder") }}
          </p>
          <p class="info">
            {{ $t("mypage.exportModal.reminderInfo1") }}
            <span class="text-danger">
              {{ $t("mypage.exportModal.reminderInfo2") }}
            </span>
          </p>
          <p class="info">
            {{ $t("mypage.exportModal.reminderInfo3") }}
          </p>
          <template v-if="activeLanguage !== undefined && activeLanguage === 'ko'">
            <p class="info_sub">
              {{ $t("mypage.exportModal.reminderInfo3Sub1") }}
            </p>
            <p class="info_sub">
              {{ $t("mypage.exportModal.reminderInfo3Sub2") }}
            </p>
          </template>
        </div>
        <div class="btn_box">
          <b-button class="next_btn" @click="onClickNext">
            {{ $t("button.next") }}
          </b-button>
        </div>
      </div>
    </b-modal>

    <b-modal
      id="klipModal"
      ref="klipModal"
      hide-header
      hide-footer
      centered
      body-class="text-center text-black my-4"
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      @hide="closeKlipModal"
    >
      <CRow>
        <CCol class="col-12">
          <CLink @click="$refs.klipModal.hide()">
            <CIcon name="cil-x" class="float-right" size="lg" />
          </CLink>
        </CCol>
      </CRow>

      <!-- 카카오톡 클립(PC)으로 연결 -->
      <template v-if="form.isKakaoKlipPc">
        <div class="w-100 d-flex justify-content-center align-items-center">
          <img
            src="/img/threespace/company/kakao_klip_logo_color.png"
            width="50"
            class="float-left"
          />
          <h5 class="ml-2 mb-0">{{ $t("wallet.modal.klipQRTitle") }}</h5>
        </div>
        <div class="w-100 d-flex justify-content-center">
          <img :src="klip.qrcode" width="160" height="160" class="border-1" />
        </div>
        <div class="w-100 text-center">
          <p>
            <span class="text-gray mr-2">{{ $t("wallet.modal.remainTime") }}</span>
            <span class="text-danger">{{ time }}</span>
          </p>
        </div>
        <div class="w-100 text-center mt-3">
          <p>
            {{ $t("wallet.modal.klipQRdescription") }}
          </p>
          <p class="text-gray">
            {{ $t("wallet.modal.klipQRdescription2") }}
          </p>
        </div>
        <div class="row d-flex justify-content-center align-items-center mt-3">
          <div class="col-2">
            <img src="/img/threespace/payment/kakaotalk-logo.svg" />
          </div>
          <div class="col-1">
            <img src="/img/threespace/payment/pointer-right.svg" />
          </div>
          <div class="col-2">
            <img src="/img/threespace/payment/kakaotalk-search.svg" />
          </div>
          <div class="col-1">
            <img src="/img/threespace/payment/pointer-right.svg" />
          </div>
          <div class="col-2">
            <img src="/img/threespace/payment/kakaotalk-scan.svg" />
          </div>
        </div>
        <div
          class="row d-flex justify-content-center align-items-center text-center mt-2 mb-5"
        >
          <div class="col-4">{{ $t("wallet.klip.exeKakao") }}</div>
          <div class="col-3">{{ $t("wallet.klip.search") }}</div>
          <div class="col-4">{{ $t("wallet.klip.scanCode") }}</div>
        </div>
      </template>
      <!-- 카카오톡 클립(모바일)으로 연결 -->
      <template v-if="form.isKakaoKlipMobile">
        <div class="w-100 text-center my-5">
          <p>
            <span class="text-gray mr-2">{{ $t("wallet.modal.remainTime") }}</span>
            <span class="text-danger">{{ time }}</span>
          </p>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { prepare, request, getResult } from "klip-sdk";
import Caver from "caver-js";
import Web3 from "web3";
import QRCode from "qrcode";

import CommonUtil from "@/mixins/CommonUtil";
import NeopinConnect from "nptconnect-client";
import WalletConnectUtil from "@/mixins/WalletConnectUtil";

export default {
  props: {
    exportCollection: {
      type: Object,
      default: () => {}
    }
  },
  mixins: [
    CommonUtil,
    WalletConnectUtil
  ],
  data: function() {
    return {
      toAddress: "",
      transferABI: {
        "inputs": [
          {
            "internalType": "address",
            "name": "from",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "to",
            "type": "address"
          },
          {
            "internalType": "uint256",
            "name": "tokenId",
            "type": "uint256"
          }
        ],
        "name": "transferFrom",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      date: this.$moment(60 * 3 * 1000),
      form: this.getInitPage(),
      klip: this.getInitKakaoKlip(),
      //TODO Tx
      isTimeout: false,
      txDate: this.$moment(60 * 10 * 1000) // 10분
    };
  },
  mounted() {},
  computed: {
    ...mapState("auth", ["me"]),
    ...mapState(["wallet"]),
    isMobile() {
      return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    },
    activeLanguage() {
      return this.getActiveLanguage();
    },
    time: function() {
      return this.date.format("mm : ss");
    },
    txTime: function() {
      return this.txDate.format("mm : ss");
    }
  },
  methods: {
    ...mapActions("profile", ["addNftExportRequestedTx"]),
    ...mapActions("transaction", ["getTxResult"]),
    ...mapActions("collection", ["updateCollectionOwner"]),
    ...mapActions("wallet", ["getNonce", "getTransactionResult"]),
    getActiveLanguage() {
      return localStorage.getItem("language") || "en";
    },
    onClickNext() {
      if (this.toAddress !== "") {
        let network = this.exportCollection.nft.network;

        let walletProvider = "";
        if (network === 'KLAYTN') {
          walletProvider = this.getKlaytnWalletProvider;
        } else if (network === 'ETHEREUM') {
          walletProvider = this.getEthereumWalletProvider;
        } else if (network === 'POLYGON') {
          walletProvider = this.getPolygonWalletProvider;
        }

        if (walletProvider === "Kaikas") {
          this.kaikasTransferRequest();
        } else if (walletProvider === "Klip") {
          this.klipTransferRequest();
        } else if (walletProvider === "MetaMask") {
          this.metamaskTransferRequest();
        } else if (walletProvider.includes("wc-")) {
          this.walletConnectTransferRequest();
        } else if (walletProvider === "Neopin") {
          this.neopinTransferRequest();
        } else {
          alert(this.$t("wallet.validation.checkProvider"));
        }
        
      } else {
        this.$store.commit("setCommonAlertModalInfo", {
          msgHtml: "받을 주소를 입력 해 주세요.",
          show: true,
          fontSize: "sm"
        })
      }
    },
    async walletConnectTransferRequest() {
      const provider = await this.getWalletConnectProvider();

      const network = this.exportCollection.nft.network
      const userAddress = network === "ETHEREUM" ? this.me.ethereumAddr : network === "POLYGON" ? this.me.polygonAddr : null

      if(provider){
        const web3 = new Web3(provider);
        const data = web3.eth.abi.encodeFunctionCall(
          this.transferABI,
          [
            userAddress,
            this.toAddress, 
            this.$options.filters.decimalToHexdecimal(this.exportCollection.tokenId*1)
          ]
        );

        const tx = {
          from: userAddress,
          to: this.exportCollection.nft.contractAddr,
          data
        }

        const infuraWeb3 = new Web3(new Web3.providers.HttpProvider(process.env.VUE_APP_INFURA_URL))
        let maxPolling = 10;
        let pollingInterval = 15000;

        provider.request({
          method: 'eth_sendTransaction',
          params: [tx]
        })
          .then((hash) => {
            this.$log.info("WalletConnect sendTransaction:", hash);
            this.$store.commit("setShowLoading", {
              show: true,
              msg: this.$t("loading.nftArtpool.wallet.progressing", {type: this.$t("mypage.export")})
            });
            const timer = setInterval(() => infuraWeb3.eth.getTransactionReceipt(hash).then((result) => {
              if(maxPolling > 0) {
                if(result) {
                  if(result.status){
                    this.updateCollectionOwner({
                      idxCollection: this.exportCollection.idx, 
                      address: this.toAddress
                    }).then(() => {
                      // 메시지 팝업
                      this.$store.commit("setCommonAlertModalInfo", {
                        msgHtml: this.$t("mypage.exportSuccess"),
                        show: true,
                        fontSize: "sm"
                      });
                      this.$emit("onExportNftSuccess")
                      this.hide();
                      this.$store.commit("setShowLoading", { show: false, msg: "" });
                      clearInterval(timer);
                    })
                  }else {
                    this.hide();
                    this.$store.commit("setCommonAlertModalInfo", {
                      msgHtml: this.$t("mypage.exportFail"),
                      show: true,
                      fontSize: "sm"
                    });
                    this.$store.commit("setShowLoading", { show: false, msg: "" });
                    clearInterval(timer);
                  }
                }
              } else {
                this.$store.commit("setShowLoading", { show: false, msg: "" });
                this.$store.commit("setCommonAlertModalInfo", {
                  msgHtml: this.$t("처리가 지연되고 있습니다. 관리자에게 문의하세요."),
                  show: true,
                  fontSize: "sm"
                })
                this.hide();
                clearInterval(timer);
              }
              maxPolling--;
            }), pollingInterval);
          })
          .catch((error) => {
            this.$log.error("WalletConnect sendTransaction:", error);
            this.$store.commit("setShowLoading", { show: false, msg: "" });
            this.hide();
            this.$store.commit("setCommonAlertModalInfo", {
              msgHtml: this.$t("mypage.exportFail"),
              show: true,
              fontSize: "sm"
            });
          })
      } else {
        alert(this.$t("wallet.validation.checkProvider"));
      }
    },
    getCachedSession() {
      const local = localStorage ? localStorage.getItem("walletconnect") : null;

      let session = null;
      if (local) {
        session = JSON.parse(local);
      }
      return session;
    },
    metamaskTransferRequest() {
      const network = this.exportCollection.nft.network
      const userAddress = network === "ETHEREUM" ? this.me.ethereumAddr : network === "POLYGON" ? this.me.polygonAddr : null

      if(!userAddress) {
        alert(this.$t("wallet.validation.checkWalletAddr"));
      } else if (this.checkMetamaskWithNetwork(network, userAddress)) {
        const web3 = new Web3(window.ethereum);
        const data = web3.eth.abi.encodeFunctionCall(
            this.transferABI,
            [
              userAddress,
              this.toAddress, 
              this.$options.filters.decimalToHexdecimal(this.exportCollection.tokenId*1)
            ]
        );

        web3.eth.sendTransaction({
          from: userAddress,
          to: this.exportCollection.nft.contractAddr,
          data
        })
        .on("transactionHash", (hash) => {
          this.$log.info("sendTransaction hash:", hash); //TODO
          this.$store.commit("setShowLoading", {
            show: true,
            msg: this.$t("loading.nftArtpool.wallet.progressing", {type: this.$t("mypage.export")})
          });
          // 지갑승인 요청 TX 저장 / 결과 조회
          const requestedInfo = {
            status: "requested",
            chain: network,
            transactionHash: hash,
            toAddr: this.exportCollection.nft.contractAddr,
            state: "NFT_EXPORT"
          };
          this.addNftExportRequestedTx(requestedInfo)
            .then(data => {
              if (data.status !== undefined && data.status !== null && data.status === "requested") {
                // 타이머 초기화
                this.txDate = this.$moment(60 * 10 * 1000); // 10분
                this.isTimeout = false;
                clearInterval(this.timer);
                clearInterval(this.totalTimer);

                // 지갑승인 결과 조회 전체 타이머
                this.setTxTotalTimer();
                // 지갑승인 결과 조회
                this.setTxResultTimer(data.requestKey);
              } else {
                this.$store.commit("setShowLoading", { show: false, msg: "" });
                this.hide();
              }
            })
            .catch(error => {
              this.$store.commit("setShowLoading", { show: false, msg: "" });
              this.hide()
            })
        })
        .on("error", (error) => {
          // this.$log.info("sendTransaction error:", error); //TODO
          this.$store.commit("setShowLoading", { show: false, msg: "" });
          this.hide()
        });
      }
    },
    setTxTotalTimer() {
      if (!this.isTimeout) {
        this.totalTimer = setInterval(() => {
          this.txDate = this.txDate.clone().subtract(1, "seconds");
          if (this.txTime === "00 : 00") {
            // 타이머 초기화
            this.isTimeout = true;
            clearInterval(this.timer);
            clearInterval(this.totalTimer);

            this.$store.commit("setShowLoading", { show: false, msg: "" });
            this.hide();
            
            // 메시지 팝업 > TODO 메시지 수정필요
            this.$store.commit("setCommonAlertModalInfo", {
              msgHtml: "블록체인 트랜잭션 처리가 지연되고 있습니다. 잠시 후 처리결과를 확인해주세요.",
              show: true,
              fontSize: "sm"
            });
          }
        }, 1000);
      }
    },
    setTxResultTimer(_requestKey) {
      // 처리결과 메시지 설정(디폴트)

      if (!this.isTimeout) {
        this.timer = setInterval(() => {
          this.getTxResult(_requestKey)
            .then(data => {
              this.$log.log("getTxResult() data=", data); //TODO
              if (data.status === "completed") {
                // 타이머 초기화
                this.isTimeout = true;
                clearInterval(this.timer);
                clearInterval(this.totalTimer);

                this.$store.commit("setShowLoading", { show: false, msg: "" });

                if (data.resultStatus === "success") {
                  // 유형별 추가 처리 실행
                  this.updateCollectionOwner({
                    idxCollection: this.exportCollection.idx, 
                    address: this.toAddress
                  }).then(() => {
                    // 메시지 팝업
                    this.$store.commit("setCommonAlertModalInfo", {
                      msgHtml: this.$t("mypage.exportSuccess"),
                      show: true,
                      fontSize: "sm"
                    });
                    this.$emit("onExportNftSuccess")
                    this.hide();
                  })
                } else if (data.resultStatus === "fail") {
                  // 유형별 추가 처리 실행
                }
              }
            })
            .catch(error => {
              this.$log.error(error);
              // 타이머 초기화
              this.isTimeout = true;
              clearInterval(this.timer);
              clearInterval(this.totalTimer);

              this.$store.commit("setShowLoading", { show: false, msg: "" });
              this.hide();

              // 메시지 팝업
              this.$store.commit("setCommonAlertModalInfo", {
                msgHtml: this.$t("mypage.exportFail"),
                show: true,
                fontSize: "sm"
              });
            });
        }, 3000);
      }
    },
    kaikasTransferRequest() {
      if (this.checkKaikas()) {
        const caver = new Caver(window.klaytn);
        const data = caver.klay.abi.encodeFunctionCall(
            this.transferABI,
            [
              this.me.klaytnAddr,
              this.toAddress,
              this.$options.filters.decimalToHexdecimal(this.exportCollection.tokenId*1)
            ]
        )
        caver.klay.sendTransaction({
          type: 'SMART_CONTRACT_EXECUTION',
          from: this.me.klaytnAddr,
          to: this.exportCollection.nft.contractAddr,
          gas: 300000,
          data
        })
        .on('error', () => {
          this.$store.commit("setCommonAlertModalInfo", {
            msgHtml: this.$t("mypage.exportFail"),
            show: true,
            fontSize: "sm"
          })
          this.hide();
        })
        .on('receipt', (data) => {
          if (data.status) {
            // 유형별 추가 처리 실행
            this.updateCollectionOwner({
              idxCollection: this.exportCollection.idx, 
              address: this.toAddress
            }).then(() => {
              // update 성공 처리
              this.$store.commit("setCommonAlertModalInfo", {
                msgHtml: this.$t("mypage.exportSuccess"),
                show: true,
                fontSize: "sm"
              })
              this.hide();
              this.$emit("onExportNftSuccess")
            })
          } else {
            this.$store.commit("setCommonAlertModalInfo", {
              msgHtml: this.$t("mypage.exportFail"),
              show: true,
              fontSize: "sm"
            })
            this.hide();
          }
        });
      } else {
        this.$store.commit("setCommonAlertModalInfo", {
          msgHtml: this.$t("wallet.validation.notFountKaikas"),
          show: true,
          fontSize: "sm"
        })
        this.hide();
      }
    },
    neopinTransferRequest() {
      const session = this.getCachedSession();

      if (session) {
        const connector = new NeopinConnect({ session })
        const caver = new Caver();
        const data = caver.klay.abi.encodeFunctionCall(
          this.transferABI,
          [
            this.me.klaytnAddr,
            this.toAddress,
            this.$options.filters.decimalToHexdecimal(this.exportCollection.tokenId*1)
          ]
        )

        this.getNonce(this.me.klaytnAddr).then((nonce) => {
          const tx = {
            from: this.me.klaytnAddr,
            to: this.exportCollection.nft.contractAddr,
            gas: 300000,
            gasPrice: caver.utils.convertToPeb(750, 'Ston'),
            value: 0,
            nonce,
            data
          }

          connector.sendTransaction(tx)
            .then(async (hash) => {
              this.$log.log("sendTransaction hash:", hash);
              await this.sleep(2000);
              this.getTransactionResult(hash).then((receipt) => {
                if (receipt.status) {
                  // 유형별 추가 처리 실행
                  this.updateCollectionOwner({
                    idxCollection: this.exportCollection.idx,
                    address: this.toAddress
                  }).then(() => {
                    // update 성공 처리
                    this.$store.commit("setCommonAlertModalInfo", {
                      msgHtml: this.$t("mypage.exportSuccess"),
                      show: true,
                      fontSize: "sm"
                    })
                    this.hide();
                    this.$emit("onExportNftSuccess")
                  })
                } else {
                  this.$store.commit("setCommonAlertModalInfo", {
                    msgHtml: this.$t("mypage.exportFail"),
                    show: true,
                    fontSize: "sm"
                  })
                  this.hide();
                }
              })
                .catch((error) => {
                  this.$store.commit("setCommonAlertModalInfo", {
                    msgHtml: this.$t("mypage.exportFail"),
                    show: true,
                    fontSize: "sm"
                  })
                  this.hide();
                })
            })
            .catch((error) => {
              this.$log.error("sendTransaction error:", error);
              this.$store.commit("setCommonAlertModalInfo", {
                msgHtml: this.$t("mypage.exportFail"),
                show: true,
                fontSize: "sm"
              })
              this.hide();
            })
        })
          .catch((error) => {
            this.$log.error("Can't Get Nonce : ", error);
            alert(error);
          })
      } else {
        alert(this.$t("wallet.validation.checkProvider"));
      }
    },
    klipTransferRequest() {
      const bappName = "3space Art";
      const from = this.me.klaytnAddr;
      const to = this.exportCollection.nft.contractAddr;
      const value = "0";
      // const tokenId = this.$options.filters.decimalToHexdecimal(this.exportCollection.tokenId*1);
      const tokenId = this.exportCollection.tokenId;
      const abi = JSON.stringify(this.transferABI);
      const params = `["${this.me.klaytnAddr}", "${this.toAddress}", "${tokenId}"]`;

      prepare
        .executeContract({ bappName, from, to, value, abi, params })
        .then(data => {
          this.klip.requestKey = data.request_key;
          this.sendNext(data);
        })
        .catch(error => {
          this.$log.error("klip prepare error", error)
          this.$store.commit("setCommonAlertModalInfo", {
            msgHtml: this.$t("mypage.exportFail"),
            show: true,
            fontSize: "sm"
          })
          this.hide();
        });
    },
    sendNext(data) {
      if (data.err) {
        this.$store.commit("setCommonAlertModalInfo", {
          msgHtml: this.$t("mypage.exportFail"),
          show: true,
          fontSize: "sm"
        })
        this.hide();
      } else {
        if (data.request_key) {
          // 타이머
          this.isTimeout = false;
          this.date = this.$moment(60 * 3 * 1000); // 3분
          clearInterval(this.timer);
          clearInterval(this.totalTimer);

          if (this.isMobile){
            // 카카오톡 클립 > 정보제공 요청
            request(data.request_key, true);
            this.form = this.getInitPage();
            this.form.isKakaoKlipMobile = true;
          } else {
            // QR 코드 생성
            this.createQRCode("https://klipwallet.com/?target=/a2a?request_key=" + data.request_key);
            if (this.klip.qrcode != null) {
              this.$refs["klipModal"].show();
            }
          }
          // 결과 조회 전체 타이머(QR 남은 시간)
          this.setKlipTotalTimer();
          // 결과 조회
          this.setKlipResultTimer();
        } else {
          this.hide();
          if(this.isMobile){
            this.$store.commit("setCommonAlertModalInfo", {
              msgHtml: this.$t("stake.failTryAgain"),
              show: true,
              fontSize: "sm"
            })
          } else {
            this.$store.commit("setCommonAlertModalInfo", {
              msgHtml: this.$t("stake.failKakaoQR"),
              show: true,
              fontSize: "sm"
            })
          }
        }
      }
    },
    setKlipTotalTimer() {
      if (!this.isTimeout) {
        this.totalTimer = setInterval(() => {
          this.date = this.date.clone().subtract(1, "seconds");
          if (this.time === "00 : 00") {
            clearInterval(this.timer);
            clearInterval(this.totalTimer);
            this.$refs["klipModal"].hide();
          }
        }, 1000);
      }
    },
    setKlipResultTimer() {
      if (!this.isTimeout) {
        this.timer = setInterval(() => {
          getResult(this.klip.requestKey)
            .then(data => {
              if (data.status === "completed") {
                if (data.result.status === "success") {
                  clearInterval(this.timer);
                  this.isTimeout = true;
                  this.updateCollectionOwner({
                    idxCollection: this.exportCollection.idx, 
                    address: this.toAddress
                  }).then(() => {
                    // update 성공 처리
                    this.$emit("onExportNftSuccess")
                    // 열린 모달 닫기
                    this.$refs["klipModal"].hide();
                    this.hide();
                    this.$store.commit("setCommonAlertModalInfo", {
                      msgHtml: this.$t("mypage.exportSuccess"),
                      show: true,
                      fontSize: "sm"
                    })
                  })
                } else if (data.result.status === "pending") {
                  // pending : success될때까지 실행
                  this.isTimeout = false;
                } else {
                  clearInterval(this.timer);
                  this.isTimeout = true;
                  this.$refs["klipModal"].hide();
                  this.hide();
                  alert(this.$t("mypage.exportFail"));
                }
              } else if (data.status === "canceled") {
                this.hide();
                this.$refs["klipModal"].hide();
              }
            })
            .catch(error => {
              this.$log.error(error);
              clearInterval(this.timer);
            });
        }, 3000);
      }
    },
    getInitPage() {
      return {
        title: "CONNECT",//this.$t("wallet.modal.description"),
        isDefault: false,
        isKakaoKlipPc: false,
        isKakaoKlipMobile: false,
        errorMessage: null,
        isLogin: false,
        isChange: false,
        isEthereum: false,
        isKlaytn: false,
      };
    },
    getInitKakaoKlip() {
      return {
        qrcode: null,
        requestKey: null
      };
    },
    createQRCode(_klipURL) {
      QRCode.toDataURL(_klipURL, async (err, url) => {
        this.form = this.getInitPage();
        if (err) {
          this.klip.qrcode = null;
        } else {
          this.form.isKakaoKlipPc = true;
          this.form.title = this.$t("wallet.modal.klipQRTitle");
          this.klip.qrcode = url;
        }
      });
    },
    show() {
      this.$refs["export-collection-modal"].show();
    },
    hide() {
      this.$refs["export-collection-modal"].hide();
    },
    closeModal() {
      this.toAddress = "";
      this.hide();
    },
    closeKlipModal() {
      clearInterval(this.timer);
      clearInterval(this.totalTimer);
    },
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
  },
  filters: {
    decimalToHexdecimal: value => {
      if (value !== undefined) {
        return "0x" + value.toString(16)
      } else {
        return "";
      }
    }
  }
};
</script>
